import { inject, Injectable, makeEnvironmentProviders } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  Observable,
  throwError,
  catchError,
  filter,
  skip,
  switchMap,
  takeUntil,
  EMPTY,
  of,
  take,
} from 'rxjs';
import { AuthenticationFacade } from './authentication.facade';
import {
  AuthenticationContextToken,
  NoAccessContextToken,
} from '@cca-infra/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private authentication: AuthenticationFacade = inject(AuthenticationFacade);
  private router = inject(Router);

  // intercept the request
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // if context is refresh then pass through else handle like normal request
    const needIntercept = request.context.get(AuthenticationContextToken);
    if (!needIntercept) {
      return next.handle(request);
    }

    // while refreshing wait until, refreshing is done
    const refreshInProgress = this.authentication.refreshTokenInProcess();
    if (refreshInProgress) {
      return this.waitUntilRefreshComplete(request, next);
    }

    // add AuthorizationHeader and handle request
    return next.handle(this.addAuthorizationHeader(request)).pipe(
      catchError((error: HttpEvent<unknown>) => {
        /**
         * Reasoning for each status code why it should refresh:
         * 401:
         * our access token is expired
         *
         * 403:
         * our roles could be changed, but frontend might not know about possible permission changes therefore a user would get a no-access page.
         * However his menu would still show the item's which should not longer be accessible, therefore we should refresh the data.
         */
        const errorCode =
          error && (error as unknown as HttpErrorResponse).status;
        if (errorCode === 401 || errorCode === 403) {
          return this.refreshAccessToken(request, next);
        }
        return throwError(() => error);
      }),
    );
  }

  private addAuthorizationHeader(
    request: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    request = request.clone({
      withCredentials: true,
    });

    if (!request.headers.has('Authorization')) {
      request = request.clone({
        withCredentials: true,
      });
    }
    return request;
  }

  private refreshAccessToken(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // request only one refresh token, don't linger until the end of times with this subscription
    const refreshInProgress = this.authentication.refreshTokenInProcess();

    // if refresh token is truthy
    if (!refreshInProgress) {
      // request for refreshing accessToken
      this.authentication.refreshAccessToken();
    }

    return this.waitUntilRefreshComplete(request, next);
  }

  private waitUntilRefreshComplete(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.authentication.refreshTokenInProcess$.pipe(
      // skip first
      skip(1),

      // only take(1) else we are never ending
      take(1),

      // skip until it becomes false
      filter((refreshInProgress) => !refreshInProgress),

      // if refresh ended, we should have new data
      switchMap(() => {
        return this.authentication.user$.pipe(
          // make sure we only get the current emission by taking 1 else this stream never ends
          take(1),
          switchMap((user) => {
            // if there is no new data we can return EMPTY here so the observables calls will end
            if (user) {
              return of(user);
            }
            return EMPTY;
          }),
        );
      }),

      // handle request with new accessToken
      switchMap(() => {
        return next.handle(this.addAuthorizationHeader(request)).pipe(
          catchError((err: HttpErrorResponse) => {
            setTimeout(() => {
              switch (err.status) {
                case 401:
                  this.authentication.logout();
                  return EMPTY;
                case 403: {
                  /**
                   * if NoAccessContext is true, we should redirect to page-not-accessible
                   * else if NoAccessContext is false we can just rethrow the error
                   */
                  const noAccessContext =
                    request.context.get(NoAccessContextToken);
                  if (noAccessContext) {
                    this.router.navigate(['page-not-accessible']);
                    return EMPTY;
                  }
                  break;
                }
              }
              return EMPTY;
            });
            throw err;
          }),
        );
      }),

      // stop listening if a error occurred
      // skip(1) since this always emits latest value
      // filter on null errors
      takeUntil(
        this.authentication.error$.pipe(
          skip(1),
          filter((error) => !!error),
        ),
      ),
    );
  }
}

export function provideAuthenticationInterceptor() {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
  ]);
}
