import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { RouterFacade } from '@cca-common/cdk';
import { AuthenticationConfigToken } from '../authentication-config.token';
import { AuthenticationFacade } from '../authentication.facade';

export const authGuardFn: CanActivateFn = () => {
  const authentication = inject(AuthenticationFacade);
  const router = inject(RouterFacade);
  const authenticationConfig = inject(AuthenticationConfigToken);

  if (authentication.authenticated()) {
    return true;
  } else {
    return router.parseUrl(authenticationConfig.urlAfterLogout);
  }
};
