import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { AuthenticationStoreState } from '../authentication.state';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { inject } from '@angular/core';
import {
  AuthenticationService,
  toAuthModel,
} from '@cca-infra/user-management/v1';
import { EMPTY, exhaustMap, pipe, race, tap, timer } from 'rxjs';
import { authenticationTimeOutInMs } from '../../constants';

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withRefresh<_>() {
  return signalStoreFeature(
    {
      state: type<AuthenticationStoreState>(),
      methods: type<{
        _navigateAfterLogout: () => void;
      }>(),
    },
    withMethods((store) => {
      const authService = inject(AuthenticationService);
      return {
        refresh: rxMethod<void>(
          pipe(
            exhaustMap(() => {
              const userId = store._realUser()?.id;
              // if there is no user data we cannot refresh
              if (!userId) {
                return EMPTY;
              }

              // set refresh indicator
              patchState(store, () => ({
                refreshingTokenInProgress: true,
                error: null,
              }));

              return race(
                // timer to timeout a refresh request this should not exceed 20.000 ms
                timer(authenticationTimeOutInMs).pipe(
                  tap(() => {
                    patchState(store, () => ({
                      refreshingTokenInProgress: false,
                      _realUser: null,
                      _projectedUser: null,
                      error: `Timeout while requesting a new accessToken, request took over ${authenticationTimeOutInMs} ms`,
                    }));
                  }),
                ),

                // request a refresh on authentication data
                authService.refreshAccessToken(userId).pipe(
                  tapResponse(
                    (user) => {
                      patchState(store, () => ({
                        refreshingTokenInProgress: false,
                        _realUser: toAuthModel(user),
                      }));
                    },
                    () => {
                      patchState(store, () => ({
                        refreshingTokenInProgress: false,
                        _realUser: null,
                        _projectedUser: null,
                        error: `A error has occurred while refreshing`,
                      }));
                    },
                  ),
                ),
              );
            }),
          ),
        ),
      };
    }),
  );
}
