import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';
import {
  DerivedAuthViewModel,
  PermissionKey,
  PermissionType,
  SystemFeature,
} from '@cca-infra/user-management/v1';
import { checkFeature, checkPermission } from './util';
import { AuthenticationStore } from './state';
import { toObservable } from '@angular/core/rxjs-interop';
import { UserAuthenticationRequestModel } from './model';

@Injectable({ providedIn: 'root' })
export class AuthenticationFacade {
  private store = inject(AuthenticationStore);

  readonly authenticated$ = toObservable(this.store.authenticated);
  readonly authenticated = this.store.authenticated;

  readonly realUser$ = toObservable(this.store.realUser);
  readonly realUser = this.store.realUser;

  readonly user$ = toObservable(this.store.user);
  readonly user = this.store.user;

  readonly error$ = toObservable(this.store.error);
  readonly error = this.store.error;

  readonly authenticationInProcess$ = toObservable(
    this.store.authenticationInProgress,
  );
  readonly authenticationInProgress = this.store.authenticationInProgress;

  readonly refreshTokenInProcess$ = toObservable(
    this.store.refreshingTokenInProgress,
  );
  readonly refreshTokenInProcess = this.store.refreshingTokenInProgress;

  readonly permissions$ = toObservable(this.store.permissions);
  readonly permissions = this.store.permissions;

  readonly realPermissions$ = toObservable(this.store.realPermissions);
  readonly realPermissions = this.store.realPermissions;

  authenticateSso(accessToken: string) {
    this.store.loginSso({ accessToken: accessToken });
  }

  hasPermission$(
    permissionKey: PermissionKey,
    permissionType?: PermissionType,
  ) {
    return this.permissions$.pipe(
      map((userPermissions) => {
        return checkPermission(userPermissions, permissionKey, permissionType);
      }),
    );
  }

  hasPermissions$(
    permissionKey: PermissionKey[],
    permissionType?: PermissionType[],
  ) {
    return this.permissions$.pipe(
      map((permissionEntries) => {
        return checkPermission(
          permissionEntries,
          permissionKey,
          permissionType,
        );
      }),
    );
  }

  checkPermission(
    permissions: PermissionKey | PermissionKey[],
    permissionType?: PermissionType | undefined,
  ): boolean {
    const permissionEntries = this.permissions();
    return checkPermission(permissionEntries, permissions, permissionType);
  }

  checkRealPermission(
    permissions: PermissionKey | PermissionKey[],
    permissionType?: PermissionType | undefined,
  ): boolean {
    const permissionEntries = this.realPermissions();
    return checkPermission(permissionEntries, permissions, permissionType);
  }

  checkFeature(features: SystemFeature | SystemFeature[]): boolean {
    const userFeatures = this.store.user()?.enabledFeatures;
    return checkFeature(userFeatures, features);
  }

  projectionUser(user: DerivedAuthViewModel) {
    this.store.projectUser(user);
  }

  projectionClear() {
    this.store.clearProjection();
  }

  clearProgressIndicators() {
    this.store.clearProgressIndicators();
  }

  login(loginRequest: UserAuthenticationRequestModel) {
    this.store.login(loginRequest);
  }

  logout() {
    // broadcast this action to other tabs
    this.store.logout();
  }

  refreshAccessToken() {
    if (!this.refreshTokenInProcess()) {
      this.store.refresh();
    }
  }
}
