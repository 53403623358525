import { inject } from '@angular/core';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import {
  AuthenticationStoreState,
  initialAuthenticationState,
} from '../authentication.state';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, tap } from 'rxjs';
import { AuthenticationService } from '@cca-infra/user-management/v1';

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withLogout<_>() {
  return signalStoreFeature(
    {
      state: type<AuthenticationStoreState>(),
      methods: type<{ _navigateAfterLogout(): void }>(),
    },
    withMethods((store) => {
      const authService = inject(AuthenticationService);

      return {
        logout: rxMethod<void>(
          pipe(
            tap(() => {
              authService.logout().subscribe();

              // clear authenticationState
              patchState(store, () => initialAuthenticationState());
            }),
          ),
        ),
      };
    }),
  );
}
