import { inject, ApplicationRef, Signal } from '@angular/core';
import { RouterFacade } from '@cca-common/cdk';
import { Snackbar } from '@cca-common/snackbar';
import {
  signalStoreFeature,
  type,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { concatLatestFrom } from '@ngrx/operators';
import { filter, pairwise, pipe, tap } from 'rxjs';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { AuthenticationConfigToken } from '../../authentication-config.token';
import { AuthenticationStoreState } from '../authentication.state';

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withNavigation<_>() {
  return signalStoreFeature(
    {
      state: type<AuthenticationStoreState>(),
      computed: type<{
        authenticated: Signal<boolean>;
      }>(),
    },
    withMethods(() => {
      const snackbar = inject(Snackbar);
      const router = inject(RouterFacade);
      const config = inject(AuthenticationConfigToken);
      const appRef = inject(ApplicationRef);

      return {
        _navigateAfterLogout: rxMethod<void>(
          pipe(
            tap(() => {
              snackbar.openSuccess(t('authentication.snackbar.logoutMessage'));
              // navigateByUrl does not support queryParams, therefore we should use the `navigate` method
              router
                .navigate([config.urlAfterLogout], {
                  queryParams: {
                    url: router.url,
                  },
                })
                .then(() => {
                  appRef.tick();
                });
            }),
          ),
        ),
        _navigateAfterLogin: rxMethod<void>(
          pipe(
            concatLatestFrom(() => router.queryParam$('url')),
            tap((results) => {
              const urlQuery = results[1];
              const url = Array.isArray(urlQuery) ? urlQuery.at(0) : urlQuery;
              if (url) {
                router.navigateByUrl(url).then(() => {
                  appRef.tick();
                });
              } else {
                router.navigateByUrl(config.urlAfterLogin).then(() => {
                  appRef.tick();
                });
              }
              appRef.tick();
            }),
          ),
        ),
      };
    }),
    withMethods((store) => {
      return {
        _listenForAuthenticationChanges: rxMethod<boolean>(
          pipe(
            // pair incoming values
            pairwise(),
            filter((pair) => pair[0] !== pair[1]),
            tap((pair) => {
              const authenticated = pair[1];
              if (authenticated) {
                store._navigateAfterLogin();
              } else {
                store._navigateAfterLogout();
              }
            }),
          ),
        ),
      };
    }),
    withHooks((store) => {
      return {
        onInit: () => {
          store._listenForAuthenticationChanges(store.authenticated);
        },
      };
    }),
  );
}
