import {
  patchState,
  signalStoreFeature,
  type,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import { AuthenticationStoreState } from '../authentication.state';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { DerivedAuthViewModel } from '@cca-infra/user-management/v1';
import { EMPTY, exhaustMap, pipe, switchMap, tap } from 'rxjs';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AcceptanceService,
  LegalDocumentType,
} from '@cca-infra/legal-management/v1';
import { TermsDialogComponent } from '@cca-common/shared-terms';

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withTermsOfuse<_>() {
  return signalStoreFeature(
    {
      state: type<AuthenticationStoreState>(),
      methods: type<{ logout: () => void }>(),
    },
    withMethods((store) => {
      const dialog = inject(MatDialog);
      const acceptanceService = inject(AcceptanceService);

      return {
        checkTermsOfUse: rxMethod<DerivedAuthViewModel | null>(
          pipe(
            exhaustMap((user) => {
              if (!user) {
                return EMPTY;
              }

              const userId = user.id;

              return acceptanceService
                .checkLegalDocumentAcceptance({
                  userId: userId,
                  legalDocumentType: LegalDocumentType.CCATermsOfUse,
                })
                .pipe(
                  switchMap((result) => {
                    if (result.userAccepted) {
                      return EMPTY;
                    }

                    return dialog
                      .open(TermsDialogComponent, {
                        data: {
                          documentType: LegalDocumentType.CCATermsOfUse,
                          userId: userId,
                          isNewUser: false,
                        },
                      })
                      .afterClosed()
                      .pipe(
                        tap((termsAccepted) => {
                          if (!termsAccepted) {
                            store.logout();
                          }

                          patchState(store, () => ({
                            _realUser: {
                              ...store._realUser(),
                              acceptedTermsOfUse: true,
                            } as DerivedAuthViewModel,
                          }));
                        }),
                      );
                  }),
                );
            }),
          ),
        ),
      };
    }),
    withHooks((store) => {
      return {
        onInit: () => {
          store.checkTermsOfUse(store._realUser);
        },
      };
    }),
  );
}
