import {
  signalStoreFeature,
  type,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import { AuthenticationStoreState } from '../authentication.state';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { debounceTime, EMPTY, exhaustMap, pipe, skip, tap } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { inject, Signal } from '@angular/core';
import {
  ClockPreferenceType,
  DerivedAuthViewModel,
  UserService,
} from '@cca-infra/user-management/v1';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { getCanonicalLocale } from '@cca-common/translations';
import { NAVIGATOR } from '@cca-common/environment';

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withLocalization<_>() {
  return signalStoreFeature(
    {
      state: type<AuthenticationStoreState>(),
      computed: type<{
        realUser: Signal<DerivedAuthViewModel | null>;
        authenticated: Signal<boolean>;
      }>(),
      methods: type<{
        refresh: () => void;
      }>(),
    },
    withMethods((store) => {
      const userService = inject(UserService);
      const translocoService = inject(TranslocoService);
      const translocoLocaleService = inject(TranslocoLocaleService);
      const navigator = inject(NAVIGATOR);

      return {
        saveLanguageToBackend: rxMethod<string>(
          pipe(
            debounceTime(500),
            skip(1),
            exhaustMap((language) => {
              const authenticated = store.authenticated();
              const authenticationInProgress = store.authenticationInProgress();
              const refreshingTokenInProgress =
                store.refreshingTokenInProgress();

              if (
                !authenticated ||
                authenticationInProgress ||
                refreshingTokenInProgress
              ) {
                return EMPTY;
              }

              const currentLanguage = store.realUser()?.languageName;
              if (currentLanguage === language || !language) {
                return EMPTY;
              }

              return userService.setUserLanguage(language).pipe(
                // if language did change we should refresh our token, else requests might return data in old language/locale.
                tap((languageDidChange) => {
                  if (languageDidChange) {
                    store.refresh();
                  }
                }),
              );
            }),
          ),
        ),
        applyLocalizationFromAuthentication:
          rxMethod<DerivedAuthViewModel | null>(
            pipe(
              // changes to language
              tap((user) => {
                const userLanguage = user?.languageName;
                if (
                  userLanguage &&
                  userLanguage !== translocoService.getActiveLang()
                ) {
                  translocoService.setActiveLang(userLanguage);
                }
              }),

              // changes to locale
              tap((user) => {
                const clockPreference = user?.clockPreferenceType;
                const locale = user?.locale;

                // backend locale does not support, specific time changes baked into the locale therefore we need to apply this here
                // we get the locale from the backend, then make specific changes to support the clockPreference of the user.
                if (locale && locale !== '') {
                  const updatedLocale = new Intl.Locale(
                    getCanonicalLocale(locale),
                    {
                      hourCycle:
                        clockPreference === ClockPreferenceType.MilitaryTime
                          ? 'h23'
                          : 'h12',
                    },
                  ).toString();
                  translocoLocaleService.setLocale(updatedLocale);
                }
                // otherwise if locale is empty, infer the locale from browser/navigator.
                else {
                  for (const lang of navigator?.languages ?? []) {
                    try {
                      // get canonical locale can throw error if incorrect locale information is provided
                      const canonicalLocale = getCanonicalLocale(lang);
                      translocoLocaleService.setLocale(canonicalLocale);

                      // if we succeeded we can break the loop
                      break;
                    } catch (e) {
                      console.warn(e);
                    }
                  }
                }
              }),
            ),
          ),
      };
    }),
    withHooks((store) => {
      const transloco = inject(TranslocoService);
      return {
        onInit: () => {
          store.saveLanguageToBackend(transloco.langChanges$);
          store.applyLocalizationFromAuthentication(store.realUser);
        },
      };
    }),
  );
}
