import { Signal, computed, inject } from '@angular/core';
import { AuthViewModel, SystemFeature } from '@cca-infra/user-management/v1';
import { checkFeature } from '../util';
import { AuthenticationStore } from '../state';

/**
 * @param userSignal Signal to retrieve the UserData
 * @param features feature(s) to check
 * @param featureType type of feature(s) to check
 * @returns Signal<boolean>
 */
function featureSignalHelper(
  userSignal: Signal<AuthViewModel | null>,
  features: SystemFeature | SystemFeature[],
) {
  return computed(() => {
    const user = userSignal();
    if (!user) {
      return false;
    }

    return checkFeature(user?.enabledFeatures ?? [], features);
  });
}

/**
 * @param features feature(s) to check
 * @param featureType type of feature(s) to check
 * @returns Signal<boolean>
 */
export function createFeatureSignal(features: SystemFeature | SystemFeature[]) {
  const store = inject(AuthenticationStore);
  const userSignal = store.user;
  return featureSignalHelper(userSignal, features);
}

/**
 * @param features feature(s) to check
 * @param featureType type of feature(s) to check
 * @returns Signal<boolean>
 */
export function createRealFeatureSignal(
  features: SystemFeature | SystemFeature[],
) {
  const store = inject(AuthenticationStore);
  const userSignal = store.user;
  return featureSignalHelper(userSignal, features);
}
