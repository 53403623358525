import { InjectionToken, Provider } from '@angular/core';
import { AuthenticationConfig } from '@cca-common/environment';

export const AuthenticationConfigToken =
  new InjectionToken<AuthenticationConfig>('[Authentication] config');

export function provideAuthenticationConfig(config: AuthenticationConfig) {
  return {
    provide: AuthenticationConfigToken,
    useValue: config,
  } as Provider;
}
